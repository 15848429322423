<template>
  <div class="setup">
    <div class="selection">
      <div v-for="a in accounts" :key="a.id">
        <button @click="active = a" :class="{ 'active': active.id == a.id}">
          <span class="color" :style="`background-color:${a.data.color}`"/>
          {{ a.data.name }}
        </button>
      </div>
    </div>
    <div class="info">
      <h4> Account Info </h4>
      <account-info :account="active"/>

      <h4> Recurring </h4>
      <recurring
        v-for="r in active.recurring"
        v-bind="r"
        :key="r.id"
        :active="active.recurring.length == 1 || (expanded && expanded.id == r.id)"
        @click="expanded = r"/>
    </div>
  </div>
</template>

<script>
import AccountInfo from '@/components/AccountInfo'
import Recurring from '@/components/Recurring'

export default {
  data() {
    return {
      active: this.accounts[0],
      expanded: null,
    }
  },
  props: {
    accounts: Array
  },
  components: {
    AccountInfo,
    Recurring,
  }
}
</script>

<style lang="scss" scoped>
.setup {
  display: flex;
  .selection {
    width: 180px;

    button {
      width: 100%;
      border: none;
      background: none;
      text-align: left;
      padding: 16px 40px;

      &.active {
        background-color: white;        
        border-left: 1px solid goldenrod;
        border-top: 1px solid goldenrod;
        border-bottom: 1px solid goldenrod;
      }
      &:not(.active) {
        cursor: pointer;
        border-right: 1px solid goldenrod;
        &:not(:hover) {
          opacity: 0.6;
          background-color: #f9f9f9;
        }
      }
    }
  }

  .color {
    display: inline-block;
    width: 8px;
    height: 8px;
  }
  .info {
    flex-grow: 1;
    padding: 16px 140px;

    h4:first-of-type {
      margin-top: 0;
    }
  }

  .recurring {
    &:not(.active) {
      cursor: pointer;
      &:not(:hover) {
        opacity: 0.6;
        background-color: #f9f9f9;
      }
    }
  }
}
</style>
