<template>
  <div class="graph" v-if="running">
    <Line :data="data" :options="options"/>
  </div>
</template>

<script>

import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

import {
  Line
} from 'vue-chartjs'

export default {
  data() {
    return {
      options: {
        scales: {
          x: {
            grid: {
              display: false
            }
          }
        },
        plugins: {
          legend: {
            position: 'right',
            align: 'start',
          }
        }
      }
    }
  },
  props: {
    accounts: Array,
    running: Array
  },
  components: {
    Line
  },
  computed: {
    data() {
      const d = {
        labels: [],
        datasets: this.accounts.map(a => {
          return {
            label: a.data.name,
            data: [],
            pointStyle: false,
            borderWidth: 2,
            borderColor: a.data.color,
            backgroundColor: a.data.color,
            hidden: a.data.name == 'Mortgage',
          }
        })
      };

      this.running.filter(r => !r.past).forEach(r => {
        d.labels.push(r.date.toLocaleDateString());
        for(let i = 0; i < r.balance.length; ++i) {
          d.datasets[i].data.push(r.balance[i]);
        }
      });
      return d;
    }
  },
}
</script>

<style lang="scss">
.graph {
}
</style>
