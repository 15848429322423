<template>
  <div class="upcoming" v-if="upcoming">
    <table>
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th v-for="a in accounts" :key="a.id">
            {{ a.data.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Today</td>
          <td></td>
          <td class="current" v-for="a in accounts" :key="a.id">
            <currency :amount="a.balance.today"/>
          </td>
        </tr>
        <tr v-for="(entry,i) in upcoming" :key="i" :class="{ income : entry.amount[0] > 0, past: entry.past }">
          <td class="date">
            <display-date :date="entry.date"/>
          </td>
          <td class="note">
            {{ entry.name }}
          </td>
          <td v-for="(amount,j) in entry.amount" :key="j" :class="{ auto: entry.auto && amount }">
            <currency :amount="amount"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Currency from '@/components/Currency'
import DisplayDate from '@/components/DisplayDate'

export default {
  props: {
    accounts: Array,
    upcoming: Array,
  },
  components: {
    Currency,
    DisplayDate,
  },
}
</script>

<style lang="scss">
.upcoming {
  table {
    width: 100%;
    border-spacing: 0 2px;

    td, th {
      text-align: right;

      &:first-of-type {
        text-align: left;
      }
    }

    td.note {
      font-style: italic;
      color: #aaa;
    }

    td.neg {
      color: red;
    }

    td.auto {
      position: relative;
      &:after {
        position: absolute;
        right: -12px;
        top: -2px;
        content: '\27f3';
        color: #777;
        font-size: 0.8em;
      }
    }

    tr.income {
      background-color: #eee;
    }

    tr.past {
      opacity: 0.4;
    }
  }
}
</style>
