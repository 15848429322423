<template>
  <div class="sign-in">
    <div class="center">
      <p>
        Please sign in to continue:
      </p>
      <button @click='signIn()'> <fa :icon="['fab', 'google']"/> Sign in with Google </button>
    </div>
  </div>
</template>

<script>
import { getAuth, signInWithRedirect, setPersistence, browserSessionPersistence, GoogleAuthProvider } from 'firebase/auth'

export default {
  methods: {
    async signIn() {
      const auth = getAuth();
      await setPersistence(auth, browserSessionPersistence);

      signInWithRedirect(auth, new GoogleAuthProvider());
    }
  }
}
</script>

<style lang="scss">

.sign-in {
  display: flex;

  align-items: center;
  justify-content: center;
  height: 100%;


  .center {
    padding: 20px 40px;
    margin: 80px 0;

    button {
      border: none;
      background-color: #555;
      &:hover { background-color: #222; }
      color: white;
      padding: 8px 32px;
      cursor: pointer;
    }
  }
}

</style>
