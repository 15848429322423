<template>
  <div class="project" v-if="project">
    <h2> {{ project.data.name }} </h2>

    <router-view @settings="reload" v-if="running"
      :accounts="accounts"
      :upcoming="upcoming"
      :running="running"
    />

    <div class="loading" v-else>
      <fa icon="circle-notch" spin/>
    </div>

  </div>
</template>

<script>
import { ProjectData } from '@/components/ProjectData';

import {
  getFirestore,
  query,
  collection,
  where,
  orderBy,
  limit,
  getDocs,
  getDoc,
  doc
} from 'firebase/firestore';

export default {
  data() {
    return {
      projectData: new ProjectData(this.$route.params.id),
      accounts: null,
      upcoming: null,
      running: null,
    }
  },
  props: {
    projects: Array,
  },
  computed: {
    project() { return this.projects.find(id => this.projectData.id) }
  },
  async mounted() {
    const p = this.projectData;
    await p.load();
    this.accounts = p.accounts; 
    this.upcoming = p.upcoming;
    this.running  = p.running;
  },
  methods: {
    reload() {
      const p = this.projectData;
      p.reload();
      this.upcoming = p.upcoming;
      this.running  = p.running;
    }
  }
}

</script>

<style lang="scss">
.project {
  padding: 40px 40px;
}
.loading {
  text-align: center;
  color: #aaa;
  padding: 80px 0;
}

</style>
