<template>
  <span>{{ date.getMonth() + 1 }}/{{ date.getDate() }}/{{ date.getYear() - 100 }}</span>
</template>

<script>
export default {
  props: {
    date: Object,
  },
}
</script>
