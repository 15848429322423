<template>
  <table>
    <tr>
      <td>Type</td>
      <td>
        <label class="choice" v-for="v in ['cash', 'credit', 'loan']" :key="v">
          <input type="radio" name='type' :value='v' :checked="v == account.data.type"/>
          <span> {{ v }} </span>
        </label>
      </td>
    </tr>
    <tr v-if="account.data.type == 'cash'">
      <td>Primary</td>
      <td>
        <label class="choice" v-for="v in [true, false]" :key="v">
          <input type="radio" name='primary' :value='v' :checked="v == account.data.primary"/>
          <span> {{ v ? 'yes' : 'no' }} </span>
        </label>
      </td>
    </tr>
    <tr v-if="account.data.type == 'credit'">
      <td>Daily</td>
      <td> 
        $<input type="number" name="daily" :value="account.data.daily" @change="$emit('settings')" @focus="$event.target.select()"/>
      </td>
    </tr>
    <tr v-if="account.data.type == 'credit' || account.data.type == 'loan'">
      <td>APR</td>
      <td>
        <input type="number" name="apr" :value="account.data.apr" @change="$emit('settings')" @focus="$event.target.select()"/>
      </td>
    </tr>
    <tr>
      <td>Color</td>
      <td>
        <input type="text" name="color" :value="account.data.color" :style="`border-color:${account.data.color}`" @focus="$event.target.select()"/>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    account: Object,
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  max-width: 512px;
  border-spacing: 0;
  margin-bottom: 60px;
  td {
    padding-bottom: 6px;
    &:not(:first-of-type) {
      text-align: right;
    }

  }
} 
.choice {
  display: inline-block;
  text-align: center;
  width: 100px;

  input[type="radio"] {
    appearance: none;
    position: absolute;

    & + span {
      display: block;
      border: 1px solid #eee;
      border-radius: 8px;
      width: 100%;
      padding: 4px 0;
    }
    &:not(:checked) + span {
      cursor: pointer;
      color: #777;
      &:not(:hover) {
        background-color: #f0f0f0;
        opacity: 0.6;
      }
    }
    &:checked + span {
      background-color: white;
      border-bottom: 2px solid goldenrod;
    }
  }
}

input[type="number"], input[type="text"] {
  text-align: right;
  padding: 8px 2px;

  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
}
</style>
