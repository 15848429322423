<template>
  <span class="currency" :class="{ neg: amount < 0 }"> {{ display }} </span>
</template>

<script>
const fmt = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

export default {
  props: {
    amount: Number,
  },
  computed: {
    display() {
      if(this.amount)
        return fmt.format(this.amount)
      return '';
    }
  },
}
</script>

<style lang="scss" scoped>
span.neg {
  color: red;
}
</style>
