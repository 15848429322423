import { createApp } from 'vue'
import { initializeApp } from 'firebase/app';
import vScrollLock from 'v-scroll-lock';

import {
  faBars,
  faTimes,
  faTimesCircle,
  faCircleNotch,
  faGear,
} from '@fortawesome/free-solid-svg-icons'
import {
  faGoogle,
} from '@fortawesome/free-brands-svg-icons'

import { library } from '@fortawesome/fontawesome-svg-core'

library.add(
  faBars,
  faTimes,
  faTimesCircle,
  faCircleNotch,
  faGear,
  faGoogle,
);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'
import firebase from './firebase';

const app = createApp(App);
app.config.globalProperties.$firebaseApp = initializeApp(firebase);
app.component('fa', FontAwesomeIcon);
app.use(vScrollLock);
app.use(router).mount('#app')
