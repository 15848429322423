<template>
  <div class="recurring" :class="{ active: active }">
    <div class="summary">
      <div class="name"> {{ name }} </div>

      <div class="weekly" v-if="type == 'weekly'">
        <span v-if="every==1">Weekly</span> <span v-else> Every {{ every }} weeks </span>
        on {{ day }}
      </div>
      <div class="monthly" v-if="type == 'monthly'">
        <span v-if="every==1">Monthly</span> <span v-else> Every {{ every }} months </span>
        on the {{ date }}
      </div>

      <currency :amount="amount" v-if="amount"/> 
    </div>

    <div class="info" v-if="active">
      <table>
        <tr>
          <td> Name </td>
          <td> <input type="text" name="name" :value="name"/> </td>
        </tr>
        <tr>
          <td> Type </td>
          <td> <input type="text" name="type" :value="type"/> </td>
        </tr>
        <tr>
          <td> Amount </td>
          <td> <input type="number" name="amount" :value="amount"/> </td>
        </tr>
        <tr>
          <td> Every </td>
          <td> <input type="number" name="every" :value="every"/> </td>
        </tr>
        <tr>
          <td> On </td>
          <td> <input type="number" name="on" :value="on"/> </td>
        </tr>
        <tr>
          <td> Starting </td>
          <td> <input type="text" name="start_on" :value="start_on"/> </td>
        </tr>

      </table>
    </div>

  </div>
</template>

<script>
import Currency from '@/components/Currency'

export default {
  props: {
    name: String,
    id: String,
    type: String,
    amount: Number,
    every: Number,
    on: Number,
    start_on: Object,
    active: Boolean,
  },
  computed: {
    day() {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      return days[this.on];
    },
    date() {
      if(Math.trunc(this.on/10) != 1) {
        if(this.on % 10 == 1) {
          return `${this.on}st`;
        } else if(this.on % 10 == 2) {
          return `${this.on}nd`;
        } else if(this.on % 10 == 3) {
          return `${this.on}rd`;
        }
      }
      return `${this.on}th`;
    },
  },
  components: {
    Currency,
  }
}
</script>

<style lang="scss" scoped>
.recurring {
  max-width: 512px;

  .name {
    width: 120px;
    position: relative;

  }

  &.active {
    .name:before {
      content: '>  ';
      position: absolute;
      left: -1em;
    }
  }

  .currency {
    padding-right: 4px;
    text-align: right;
    width: 120px;
  }
}

.summary {
  padding: 4px 0;
  display: flex;

  .weekly, .monthly {
    flex-grow: 1;
  }
}

table {
  padding: 4px 0 16px 40px;
  margin: 4px 0 20px;
  border-left: 1px solid #aaa;

  input {
    text-align: right;
  }
  td {
    text-align: right;
  }

}

</style>
