<template>
  <div class="home">
    <div class="project-list" v-if="projects">
      <h2> Projects </h2>
      <router-link :to="{ name: 'graph', params: { id: p.id }}" class="project" v-for="p in projects" :key="p.id">
        {{ p.data.name }}
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    projects: Array,
  },
}
</script>

<style lang="scss">
.home {
  padding: 120px 0;
  display: flex;
  justify-content: center;

  .project-list {
    width: 512px;    
  }

  .project {
    display: block;
    width: 100%;
    padding: 16px 32px;
    margin: 16px 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-decoration: none;
    color: inherit;
    &:hover {
      background-color: #eee;
    }
  }
}
</style>
