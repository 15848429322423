<template>
  <div class="running" v-if="running">
    <table>
      <thead>
        <tr>
          <th> &nbsp; </th>
          <th v-for="a in accounts" :key="a.id">
            {{ a.data.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="day in running" :key="day.date" :class="{ past: day.past }">
          <td>
            <display-date :date="day.date"/>
          </td>
          <td v-for="(balance,i) in day.balance" :key="i">
            <currency :amount="balance"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Currency from '@/components/Currency'
import DisplayDate from '@/components/DisplayDate'

export default {
  props: {
    accounts: Array,
    running: Array,
  },
  components: {
    Currency,
    DisplayDate,
  },
}
</script>

<style lang="scss">
.running {
  table {
    width: 100%;

    td, th {
      text-align: right;

      &:first-of-type {
        text-align: left;
      }
    }

    tr.past {
      color: #ddd;
    }
  }
}
</style>
