import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import ProjectView from '@/views/ProjectView.vue'

import GraphView from '@/views/GraphView.vue'
import RunningView from '@/views/RunningView.vue'
import UpcomingView from '@/views/UpcomingView.vue'
import SetupView from '@/views/SetupView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/projects/:id',
    component: ProjectView,
    children: [
      {
        path: '', redirect: { name: 'running' }
      },
      {
        path: 'graph',
        name: 'graph',
        component: GraphView,
      },
      {
        path: 'running',
        name: 'running',
        component: RunningView,
      },
      {
        path: 'upcoming',
        name: 'upcoming',
        component: UpcomingView,
      },
      {
        path: 'setup',
        name: 'setup',
        component: SetupView,
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'home' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
