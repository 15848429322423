<template>
  <div class="nav-header">
    <div class="title">
      <router-link to="/">TallyEasy</router-link>
    </div>
    <div class="toggle">
      <a href="#" @click.prevent="toggle()">
        <fa :icon="menu ? 'times' : 'bars'"/>
      </a>
    </div>
    <div class="menu" v-if="$route.params.id" :class="{ open: menu }">
      <ul>
        <li> <router-link :to="{ name: 'graph', params: { id: id }}">Graph</router-link> </li>
        <li> <router-link :to="{ name: 'upcoming', params: { id: id }}">Upcoming</router-link> </li>
        <li> <router-link :to="{ name: 'running', params: { id: id }}">Running</router-link> </li>
        <li> <router-link :to="{ name: 'setup', params: { id: id }}">Setup</router-link> </li>
      </ul>
    </div>
    <div class="user" v-if="user">
      <img :src="user.photoURL"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      menu: false,
    }
  },
  props: {
    user: Object,
    projects: Array,
  },
  methods: {
    toggle() {
      this.menu = !this.menu;
      this.$emit('menu-toggle', this.menu);
    },
  }
}
</script>

<style scoped lang="scss">
$height: 40px;
$background: #149106;
.nav-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  height: $height;
  width: 100%; 
  background: $background;
  color: white;
  padding: 0 16px;
  box-sizing: border-box;
  line-height: 40px;

  @media screen and (min-width: 768px) {
    padding: 0 40px;
  }

  .toggle {
    position: absolute;
    top: 0;
    right: 16px;
  }

  .menu {
    display: none;
    &.open {
      display: block;
      background: darken($background, 10%);
    };
    position: absolute;
    top: $height;
    left: 0;
    width: 100%;
  }

  .user {
    display: block;
    position: absolute;
    height: 52px;
    width: 52px;
    right: 0px;
    top: 0;
    background-color: $background;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: calc(100% - 6px);
      border-radius: 100%;
      border: 2px solid white;
    }
  }
  

  ul {
    list-style-type: none;
    display: block;
    margin: 0;
    padding: 4px 0;
  }
  li {
    padding: 4px 0;
    a {
      padding: 4px 16px;
      display: block;
      width: 100%;
    }
  }

  @media screen and (min-width: 768px) {
    .toggle {
      display: none;
    }
    .menu {
      display: block;
      position: absolute;
      top: 0;
      left: 200px;
      width: calc(100% - 400px);
      height: $height;
      .router-link-active {
        border-bottom: 2px solid white;
      }
    }

    li {
      &, a, a > * {
        padding: 2px 0;
        display: inline;
      }

      a {
        margin: 0 8px;
      }
    }
    ul {
      display: block;
      padding: 0;
      max-width: 330px;
      margin: 0 auto;
    }
  }

  a {
    color: white;
    text-decoration: none;
  }
  
}
</style>
