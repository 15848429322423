<template>
  <div class="mainapp">
    <nav-header :user="user" :projects="projects"/>
    <div v-if="ready">
      <router-view v-if="user" :projects="projects"/>
      <sign-in v-else/>
    </div>
  </div>
</template>

<script>

import {
  getAuth,
  onAuthStateChanged,
  getRedirectResult
} from 'firebase/auth'

import {
  getFirestore,
  query,
  collection,
  where,
  getDocs
} from 'firebase/firestore';

import NavHeader from '@/components/NavHeader'
import SignIn from '@/components/SignIn'

export default {
  data() {
    return {
      ready: false,
      user: null,
      projects: [],
    }
  },
  async mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      this.user = user;
      this.ready = true;

      if(user) {
        this.getProjects();
      }
    });

    const result = await getRedirectResult(getAuth());
    if(result) {
      this.user = result.user;
      this.ready = true;
    }
  },
  methods: {
    async getProjects() {
      const db = getFirestore();
      const q = query(collection(db, 'projects'), where('owner', '==', this.getUid()));

      (await getDocs(q)).forEach(p => {
        this.projects.push({ id: p.id, data: p.data()});
      });
    },
    getUid() {
      return getAuth().currentUser.uid;
    },
  },
  components: {
    NavHeader,
    SignIn,
  }
}

</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Merriweather|Roboto');

html, body {
  margin: 0;
}

* {
  box-sizing: border-box;
}

.mainapp {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Merriweather', serif;
    letter-spacing: 0.02em;
  }
  p {
    line-height: 1.5em;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  & > :nth-child(2) {
    margin-top: 40px;
    min-height: calc(100vh - 40px);
    height: 1px;
    box-sizing: border-box;
  }
}
</style>
