import {
  getFirestore,
  query,
  collection,
  where,
  orderBy,
  limit,
  getDocs,
  getDoc,
  doc
} from 'firebase/firestore'

class ProjectData {
  constructor(id) {
    this.id = id;
    this.accounts = [];
    this.db = getFirestore();
  }

  async load() {
    const p = [];
    const accounts = query(collection(this.db, 'accounts'), where('project', '==', this.id), orderBy('type'));

    (await getDocs(accounts)).forEach(a => {
      const account = { id: a.id, data: a.data(), balance: {}, recurring: [] };
      this.accounts.push(account);

      p.push(this.loadBalances(account));
      p.push(this.loadRecurring(account));
      p.push(this.loadTransactions(account));
    });

    await Promise.all(p);

    this.reload();
  }

  reload() {
    this.computeUpcoming();
    this.computeRunningTally();
  }

  isPast(date) {
    const today = new Date();
    today.setHours(0, 0, 0);

    return Math.floor(date.getTime()/1000) < Math.floor(today.getTime()/1000);
  }

  computeUpcoming() {

    const start = this.accounts
      .map(a => a.balance)
      .sort((a, b) => a.date.getTime() - b.date.getTime())[0]
      .date;
    start.setHours(0, 0, 0);

    const stop = new Date();
    stop.setHours(0, 0, 0);
    stop.setYear(stop.getFullYear() + 2);

    let upcoming = [];

    const eachOccurrence = (id, date, i, name, amount, incr) => {
      while(date.getTime() < stop.getTime()) {
        if(date.getTime() > start.getTime()) {
          const entry = {
            series: id,
            date: new Date(date),
            name: name,
            amount: new Array(this.accounts.length),
            past: this.isPast(date),
            auto: true,
          }
          entry.amount[i] = amount || (() => {});
          upcoming.push(entry);
        }
        incr();
      }
    };

    for(let i = 0; i < this.accounts.length; ++i) {
      const a = this.accounts[i];
      a.recurring.forEach(r => {

        const name = a.data.primary ? r.name : '';

        const date = new Date(r.start_on ? r.start_on.toDate() : start);

        if(r.type == 'monthly') {
          date.setDate(r.on);
          eachOccurrence(r.id, date, i, name, r.amount, () => { date.setMonth(date.getMonth() + r.every )});
        } else if(r.type == 'weekly') {
          while(date.getDay() != r.on) {
            date.setDate(date.getDate() + 1);
          }
          eachOccurrence(r.id, date, i, name, r.amount, () => { date.setDate(date.getDate() + 7 * r.every )});
        }
      });

      a.transactions.forEach(t => {
        if(t.series) {
          const entry = upcoming.find(u => { return u.series == t.series && Math.floor(u.date.getTime() / 1000) == Math.floor(t.series_date.getTime() / 1000)});
          entry.date = new Date(t.date);
          entry.amount[i] = t.amount;
          entry.auto = false;
        } else {
          const entry = {
            date: new Date(t.date),
            name: t.name,
            amount: new Array(this.accounts.length),
            past: this.isPast(t.date),
            auto: false,
          }
          entry.amount[i] = t.amount;
          upcoming.push(entry);
        }
      });
    }

    upcoming = upcoming.sort((a,b) => { return a.date.getTime() - b.date.getTime() });

    this.upcoming = upcoming;
  }

  computeRunningTally() {
    const running = [];
    let prev = -1;
    let present = 0;

    const today = new Date();
    today.setHours(0, 0, 0);

    const date = new Date(this.accounts
      .map(a => a.balance)
      .sort((a, b) => a.date.getTime() - b.date.getTime())[0]
      .date);

    this.upcoming.forEach(u => {
      while(date.getTime() <= u.date.getTime()) {
        date.setHours(0, 0, 0);
        const data = {
          date: new Date(date),
          balance: [],
          past: this.isPast(date),
        };
        if(data.past) {
          ++present;
        }

        for(let i = 0; i < this.accounts.length; ++i) {
          const a = this.accounts[i];
          const interest = (a.data.apr || 0) / 365;

          if(prev < 0 || date.getTime() < a.balance.date.getTime()) {
            data.balance[i] = a.balance.amount;
          } else {
            let b = running[prev].balance[i];
            b += a.data.daily || 0;

            data.balance[i] = b * (1 + interest);
          }
        }
        date.setDate(date.getDate() + 1);
        running.push(data);
        ++prev;
      }
 
      const day = running[prev];
      for(let i = 0; i < this.accounts.length; ++i) {

        if(typeof(u.amount[i]) == 'function') {
          const diff = day.balance[0] - day.balance[i];
          if(diff > 1000) {
            u.amount[i] = -day.balance[i] * 0.8;
          } else {
            u.amount[i] = (-day.balance[i] - diff) * 0.5;
            //u.amount[i] = -1;
          }
/*
          if(day.balance[0] > day.balance[i])
            u.amount[i] = -day.balance[i] * 0.6;
          else
            u.amount[i] = -1;
*/
        }

        if(-u.amount[i] > day.balance[i]) {
          u.amount[i] = -day.balance[i];
        }

        const amount = u.amount[i] || 0;

        day.balance[i] += amount;
        if(i > 0) {
          day.balance[0] += amount;
        }
      }
    });

    for(let i = 0; i < this.accounts.length; ++i) {
      this.accounts[i].balance.today = running[present].balance[i];
    }
    

    this.running = running;
  }

  async loadBalances(account) {
    const balances = query(
      collection(this.db, 'balances'),
      where('account', '==', account.id),
      orderBy('updated_at', 'desc'),
      limit(1)
    );

    (await getDocs(balances)).forEach(b => {
      account.balance = b.data();
      account.balance.date = account.balance.updated_at.toDate();
      account.balance.date.setHours(0, 0, 0);
    });
  }

  async loadRecurring(account) {
    const recurring = query(
      collection(this.db, 'recurring'),
      where('account', '==', account.id)
    );

    const weekly = [];
    const monthly = [];
    (await getDocs(recurring)).forEach(r => {
      const d = r.data();
      d.id = r.id;
      if(d.type == 'weekly')
        weekly.push(d);
      else
        monthly.push(d);
    });

    account.recurring = weekly.concat(monthly.sort((a,b) => a.on - b.on));
  }

  async loadTransactions(account) {
    account.transactions = [];
    const tr = query(
      collection(this.db, 'transactions'),
      where('account', '==', account.id)
    );
    (await getDocs(tr)).forEach(t => {
      const d = t.data();
      d.id = t.id;
      d.date = d.occurs_on.toDate();

      if(d.series && d.replaces) {
        d.series_date = d.replaces.toDate();
      }
      account.transactions.push(d);
    });
  }
}

export { ProjectData }
